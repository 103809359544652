import { useNavigate } from "react-router-dom";
import { LayoutHeader2 } from "../components/Interactions/LayoutHeader2";
import CampaignListing from "../components/CampaignListing";
import { AuthContextType, useAuth } from "../auth/auth-provider";

export const Campaigns: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth() as AuthContextType;

  if (!user.allowCampaignCreation) {
    navigate("/not-authorized");
    return null;
  }

  return (
    <LayoutHeader2>
      <CampaignListing />
    </LayoutHeader2>
  );
};
