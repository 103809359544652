import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  setCustomEntityUpdating,
  setEntityIdUpdate,
  setInsertEntity,
  toggleGCredentialsDialog,
  toggleOpenEntityDialog,
  clearEntities
} from "../../../../redux/slices/flowInteractions";
import { useLocation } from "react-router-dom";
import { ButtonAdd } from "../../../../assets";
import { Bell } from "../Bell";
import { Identification } from "../Identification";
import { WorkspaceSelector } from "../workspaceSelector";
import { Button, Container, ContainerLeft } from "./styles";
import { useEffect, useState } from "react";
import { AdicionarBar } from "../../../../components/adicionar-bar";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { css } from "@emotion/react";
import CreateCampaign from "../../../../components/gestaoBot/components/CreateCampaign";

interface IHeader2 {
  extends?: number;
}
export const Header2: React.FC<IHeader2> = ({ extends: Ex }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user, hasAnyPermission } = useAuth() as AuthContextType;
  const authContext = useAuth() as AuthContextType;
  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const openGCredentialsDialog = useAppSelector(
    (state) => state.flowInteractions.dialogInsertGCredentials
  );
  const buttonCreateCampaign = useAppSelector(
    (state) => state.flowInteractions.buttonCreateCampaign
  );
  const allowCampaignCreation = user.allowCampaignCreation;

  const handleOnClick = () => {
    if (location.pathname === "/entities/") {
      dispatch(toggleOpenEntityDialog());
      dispatch(setInsertEntity(true));
      dispatch(setEntityIdUpdate(-1));
      dispatch(setCustomEntityUpdating(false));
    } else {
      setVisibleSidebar(true);
    }
  };

  const handleOnClickGerarChave = () => {
    dispatch(toggleGCredentialsDialog());
  };

  useEffect(() => {
    return () => {
      dispatch(clearEntities());
    };
  }, []);

  return (
    <>
      <Container>
        <ContainerLeft extends={Ex}>
          <Identification />

          {/* <Bell news={true} /> */}

          <WorkspaceSelector />
        </ContainerLeft>

        <div
          css={css`
            @media (max-width: 500px) {
              display: none;
            }
          `}
        >
          {location.pathname === "/gcredentials" ? (
            <Button onClick={handleOnClickGerarChave}>
              <i className="pi pi-plus-circle" />
              Gerar Chave
            </Button>
          ) : (location.pathname === "/contacts" && user.admin) ||
            hasAnyPermission("campaign_create") ? (
            <Button
              onClick={() => setShowCreateCampaign(true)}
              disabled={!buttonCreateCampaign}
              style={{ display: allowCampaignCreation ? undefined : "none" }}
            >
              <i className="pi pi-plus-circle" />
              Criar Campanha
            </Button>
          ) : location.pathname === "/contacts" ? null : (
            <Button onClick={handleOnClick}>
              <i className="pi pi-plus-circle" />
              Adicionar
            </Button>
          )}
        </div>
      </Container>

      {/* {user.admin || hasAnyPermission("template_view") ? (
                          <Link
                            className="hoverNavIcon"
                            to="/templates-list/"
                            css={linkStyle}
                          >
                            {isCurrentRoute("/templates-list/") ? (
                              <img src={TemplateAtive} width={25} height={25} />
                            ) : (
                              <img src={Template} width={25} height={25} />
                            )}
                            {expanded ? "Templates" : null}
                          </Link>
                        ) : null} */}

      <AdicionarBar
        visible={visibleSidebar}
        hide={() => setVisibleSidebar(false)}
      />
      <CreateCampaign
        visible={showCreateCampaign}
        hide={() => setShowCreateCampaign(false)}
      />
    </>
  );
};
