import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  ButtonContainer,
  ContainerLeftModal,
  ContainerRightModal,
  LeftHeaderWhiter
} from "./styles";
import "./tableStyles.css";
import { Dialog } from "primereact/dialog";
import { setModalConfirmImportTemplate } from "../../../../redux/slices/flowInteractions";
import { Text } from "../../../Text";
import { botRed, girl } from "../../../../assets";
import Button from "../../../button";
import { useToast } from "../../../toast-provider";
import TemplatesService from "../../../../services/TemplatesService";
import { useNavigate, useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IoMdPlayCircle } from "react-icons/io";

interface IModalConfirmImportTemplate {
  templateSelected?: number;
}

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const ModalConfirmImportTemplate: React.FC<
  IModalConfirmImportTemplate
> = ({ templateSelected }) => {
  const dispatch = useAppDispatch();
  const openModalConfirmImportTemplate = useAppSelector(
    (state) => state.flowInteractions.modalConfirmImportTemplate
  );
  const redirect = useNavigate();
  const [templatesOptions, setTemplatesOptions] = useState<any[]>([]);
  const { user, company } = useAuth() as AuthContextType;
  const { templatesList, importTemplate } = TemplatesService();
  const [searchTemplateName, setSearchTemplateName] = useState("");
  const debouncedText = useDebounce(searchTemplateName, 600);
  const [selectedTemplateName, setSelectedTemplateName] = useState<
    string | null
  >("");

  const { botId } = useParams();
  const toast = useToast();
  const tableRef = useRef(null);
  const [scrollHeight, setScrollHeight] = useState("200px");
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(
    templateSelected
  );

  const handleImportTemplate = async () => {
    if (selectedTemplate !== null) {
      try {
        const template = await importTemplate({
          botId: Number(botId),
          templateId: selectedTemplate
        });
        toast.current?.show({
          life: 5000,
          severity: "success",
          summary: "Sucesso",
          detail: "Template importado com sucesso."
        });
        redirect(`/drawflow/${template.botId}/${template.createdBotFlowId}`, {
          relative: "route",
          replace: false
        });
        window.location.reload();
        dispatch(setModalConfirmImportTemplate(false));
      } catch (error: any) {
        toast?.current?.show({
          severity: "error",
          summary: "Erro",
          detail: error.message,
          life: 3000
        });
      }
    }
  };

  const fetchTemplatesList = async () => {
    try {
      const selectedWorkspaces = user?.workspaces?.workspaces.map(
        (_ws: { id: number }) => _ws.id
      );
      if (user?.workspaces?.workspaces?.length) {
        const data = await templatesList({
          offset: 0,
          limit: 100,
          companyId: user.admin ? company.id : user.companyId,
          name: debouncedText || "",
          workspaces: selectedWorkspaces as number[]
        });
        setTemplatesOptions(data.result);
      }
    } catch (e: any) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: e.message
      });
    }
  };

  useEffect(() => {
    fetchTemplatesList();
  }, [user.workspaces?.workspaces, debouncedText]);

  useEffect(() => {
    if (!openModalConfirmImportTemplate) {
      setSelectedTemplate(null);
      setSelectedTemplateName("");
    }
  }, [openModalConfirmImportTemplate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        setSelectedTemplate(null);
        setSelectedTemplateName("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const updateScrollHeight = () => {
      if (window.innerHeight < 700) {
        setScrollHeight("150px");
      } else if (window.innerHeight < 800) {
        setScrollHeight("200px");
      } else {
        setScrollHeight("250px");
      }
    };

    updateScrollHeight();
    window.addEventListener("resize", updateScrollHeight);

    return () => window.removeEventListener("resize", updateScrollHeight);
  }, []);

  return (
    <Dialog
      className="addWspaceDialog relative"
      style={{ border: "none" }}
      headerStyle={{
        position: "relative",
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
        border: "none",
        height: "3rem",
        paddingTop: "1.5rem",
        backgroundColor: "#ff0025"
      }}
      visible={openModalConfirmImportTemplate}
      contentStyle={{
        padding: 0,
        height: "55vh",
        maxWidth: "60vw",
        borderBottomLeftRadius: "1rem",
        borderBottomRightRadius: "1rem",
        border: "none",
        overflow: "hidden"
      }}
      onHide={() => dispatch(setModalConfirmImportTemplate(false))}
      closeOnEscape={true}
      resizable={false}
      closable={true}
      keepInViewport={false}
      draggable={false}
    >
      <LeftHeaderWhiter />
      <div className="flex h-full flex-end">
        <ContainerLeftModal>
          <div className="titleDatatable">
            <div style={{ alignItems: "center", display: "flex", gap: "10px" }}>
              <img src={botRed} className="mb-2" />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className="font-bold"
                  style={{ color: "#0A043C", fontSize: "1.02rem !important" }}
                >
                  Importar template
                </div>
                <div>Selecione o template a ser importado na lista abaixo.</div>
              </div>
            </div>
            <div>
              <div className="mt-2">
                <div className="p-inputgroup">
                  <input
                    type="text"
                    placeholder="Pesquisar Template..."
                    value={searchTemplateName}
                    onChange={(e) => setSearchTemplateName(e.target.value)}
                    className="p-inputtext p-component"
                  />
                </div>
                <div ref={tableRef}>
                  <DataTable
                    value={templatesOptions}
                    selectionMode="single"
                    onSelectionChange={(e) => {
                      if (e.value) {
                        setSelectedTemplate(e.value.id);
                        setSelectedTemplateName(e.value.name);
                      }
                    }}
                    rows={10}
                    scrollable
                    scrollHeight={scrollHeight}
                    emptyMessage="Nenhum template encontrado."
                    selection={templatesOptions.find(
                      (t) => t.id === selectedTemplate
                    )}
                  >
                    <Column field="name" header="Name" />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
          <ButtonContainer>
            <div ref={tableRef}>
              <Button
                type="button"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#046af3",
                  paddingLeft: "30px",
                  paddingRight: "30px"
                }}
                onClick={handleImportTemplate}
                disabled={
                  selectedTemplate === null || selectedTemplateName === ""
                }
              >
                Importar
              </Button>
            </div>
          </ButtonContainer>
        </ContainerLeftModal>
        <ContainerRightModal>
          <div className="imageTitle">
            <img src={girl} />
            <div>
              Deseja importar o Template para configurar um novo Fluxo de
              conversação?
            </div>
          </div>
          <div className="text">
            Será criado um novo flow de conversação com o Template selecionado.
            Informações não salvas no flow atual serão perdidas.
          </div>
          <div className="link">
            <IoMdPlayCircle
              style={{ marginRight: "0.3rem" }}
              size={25}
              color="white"
              className="relative"
            />
            <span>Confira o vídeo</span>
          </div>
        </ContainerRightModal>
      </div>
    </Dialog>
  );
};
