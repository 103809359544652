import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  BotsActive,
  Chats,
  Config,
  Dashboard,
  Entities,
  HomeIcon,
  Omini,
  Template,
  Users,
  SairGray,
  Contacts,
  Campaign
} from "../../assets";
import { Link } from "react-router-dom";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import "../comum_css/rawCss.css";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setSidebarExpanded,
  setSidebarChats
} from "../../redux/slices/sidebar";
import { Container } from "./styles";

export const SidebarDrawflow: React.FC = () => {
  const { user, hasAnyPermission, logout } = useAuth() as AuthContextType;

  const [openConfigMenu, setOpenConfigMenu] = useState(false);
  const [localExpanded, setExpanded] = useState(true);

  const toggleConfigMenu = () => {
    setOpenConfigMenu((previous) => !previous);
  };
  const dispatch = useAppDispatch();
  const ref = React.createRef();

  const iconUpDown = openConfigMenu ? (
    <MdKeyboardArrowUp />
  ) : (
    <MdKeyboardArrowDown />
  );

  const linkStyle = css`
    color: #544f77;
    display: flex;
    align-items: center;
    gap: 15px;
    text-decoration: none;
    cursor: pointer;
  `;
  const sidebarHiddenRedux = useAppSelector(
    (state) => state.sidebar.sidebarHidden
  );
  const expandedRedux = useAppSelector(
    (state) => state.sidebar.sidebarExpanded
  );

  const handleSair = () => {
    logout();
    dispatch(setSidebarChats(false));
  };

  return (
    <div>
      <Container
        hidden={sidebarHiddenRedux}
        // expandedRedux={expandedRedux}
        className={`${expandedRedux ? "tamanhoExpandedDf" : "tamanhoDf"}`}
        onMouseOver={() => {
          dispatch(setSidebarExpanded(true));
          setExpanded((previous) => !previous);
        }}
        onMouseOut={() => {
          dispatch(setSidebarExpanded(false));
          setExpanded(false);
        }}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;
            padding-top: 65px;
            border-top: 1px solid #d2d2d2;
            border-right: 1px solid #d2d2d2;
            border-top-right-radius: 20px;
            background-color: #ffffff;
          `}
        >
          <div
            css={css`
              align-items: ${expandedRedux ? "flex-start" : "center"};
              display: flex;
              flex: 1 1 auto;
              flex-direction: column;
              gap: 25px;
              padding: 20px;
            `}
          >
            <Link className="hoverNavIcon" to="/home" css={linkStyle}>
              <img src={HomeIcon} width={25} height={25} />

              {expandedRedux ? "Home" : null}
            </Link>

            {user.admin || hasAnyPermission("dashboard_view") ? (
              <Link className="hoverNavIcon" to="/dashboard" css={linkStyle}>
                <img src={Dashboard} width={25} height={25} />

                {expandedRedux ? "Dashboard" : null}
              </Link>
            ) : null}

            {user.admin || hasAnyPermission("omnichannel_view") ? (
              <Link className="hoverNavIcon" to="/omnichannel" css={linkStyle}>
                <img src={Omini} width={25} height={25} />

                {expandedRedux ? "Omnichannel" : null}
              </Link>
            ) : null}

            {user.admin || hasAnyPermission("dialog_view") ? (
              <Link className="hoverNavIcon" to="/rooms/" css={linkStyle}>
                <img src={Chats} width={25} height={25} />

                {expandedRedux ? "Gestão de salas" : null}
              </Link>
            ) : null}

            {user.admin || hasAnyPermission("entity_view") ? (
              <Link className="hoverNavIcon" to="/entities/" css={linkStyle}>
                <img src={Entities} width={25} height={25} />

                {expandedRedux ? "Entidades" : null}
              </Link>
            ) : null}

            {user.admin || hasAnyPermission("bot_view") ? (
              <Link className="hoverNavIcon" to="/chatbots" css={linkStyle}>
                <img src={BotsActive} width={25} height={30} />

                {expandedRedux ? "Meus robôs" : null}
              </Link>
            ) : null}

            {user.admin || hasAnyPermission("template_view") ? (
              <Link
                className="hoverNavIcon"
                to="/templates-list/"
                css={linkStyle}
              >
                <img src={Template} width={25} height={25} />

                {expandedRedux ? "Templates" : null}
              </Link>
            ) : null}

            {user.admin || hasAnyPermission("user_view") ? (
              <Link className="hoverNavIcon" to="/users/" css={linkStyle}>
                <img src={Users} width={25} height={25} />

                {expandedRedux ? "Usuários" : null}
              </Link>
            ) : null}

            {user.admin || hasAnyPermission("contact_view") ? (
              <Link className="hoverNavIcon" to="/contacts" css={linkStyle}>
                <img src={Contacts} width={25} height={25} />

                {expandedRedux ? "Contatos" : null}
              </Link>
            ) : null}

            {user.allowCampaignCreation || hasAnyPermission("campaign_view") ? (
              <Link className="hoverNavIcon" to="/campaigns" css={linkStyle}>
                <img src={Campaign} width={25} height={25} />

                {expandedRedux ? "Campanhas" : null}
              </Link>
            ) : null}

            <div
              css={css`
                border: 1px solid #d2d2d2;
                width: 100%;
                margin-bottom: 0;
              `}
            />

            <a
              onClick={() => toggleConfigMenu()}
              css={linkStyle}
              className="colorWokspaceConfig"
            >
              <img src={Config} width={25} height={25} />

              {expandedRedux ? "Configurações" : null}

              {localExpanded && iconUpDown}
            </a>

            {openConfigMenu && localExpanded && (
              <div className="configContainer">
                <ul
                  style={{
                    marginLeft: "4px",
                    position: "relative",
                    bottom: "10px"
                  }}
                >
                  <li>
                    {user.admin || hasAnyPermission("workspace_view") ? (
                      <Link to="/Workspaces/" className="colorWokspaceConfig">
                        Workspace
                      </Link>
                    ) : null}
                  </li>

                  <li className="mt-2">
                    {user.admin || hasAnyPermission("profile_view") ? (
                      <Link to="/profiles" className="colorWokspaceConfig">
                        Permissionamento
                      </Link>
                    ) : null}
                  </li>

                  <li className="mt-2">
                    {user.admin || hasAnyPermission("credential_view") ? (
                      <Link to="/gcredentials" className="colorWokspaceConfig">
                        Credenciais
                      </Link>
                    ) : null}
                  </li>

                  {user.admin ? (
                    <li className="mt-2">
                      <Link
                        to="/change-company"
                        className="colorWokspaceConfig"
                      >
                        Trocar de empresa
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </div>
            )}
            <div onClick={handleSair} css={linkStyle}>
              <img src={SairGray} alt="Person Icon" width={30} height={30} />
              {localExpanded ? "Sair" : null}
            </div>
          </div>

          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              padding-bottom: 20px;
              color: #999999;
            `}
          >
            © {user.plataform.displayName}
          </div>
        </div>
      </Container>
    </div>
  );
};
