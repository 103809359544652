import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ToastMessage } from "primereact/toast";

interface MyToast extends ToastMessage {
  count: number;
  show: boolean;
}
interface IUser {
  name: string;
  avatarURL?: string;
  toastApp?: MyToast;
  openMyAccount: boolean;
  allowCampaignCreation: boolean;
}
const initialState: IUser = {
  name: "",
  avatarURL: "",
  toastApp: {} as MyToast,
  openMyAccount: false,
  allowCampaignCreation: false
};
const UserSlice = createSlice({
  name: "flowInteractions",
  initialState,
  reducers: {
    setOpenModalMyAccount(state, action: PayloadAction<boolean>) {
      state.openMyAccount = action.payload;
    },
    setUserName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setToast(state, action: PayloadAction<MyToast>) {
      state.toastApp = action.payload;
    },
    setAllowCampaignCreation(state, action: PayloadAction<boolean>) {
      state.allowCampaignCreation = action.payload;
    }
  }
});

export const {
  setUserName,
  setToast,
  setOpenModalMyAccount,
  setAllowCampaignCreation
} = UserSlice.actions;

export default UserSlice.reducer;
