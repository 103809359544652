import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: #fff;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
    height: 9rem;
    gap: 15px;
    padding: 15px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 0;
    justify-content: center;
    height: 18.5rem;
    padding: 15px;
    gap: 15px;
  }

  @media screen and (max-width: 500px) {
    height: 16rem;
  }
`;

export const Divisor = styled.div`
  transform: rotate(90deg);
  height: 0px;
  width: 33px;
  margin: 0 1rem;
  opacity: 0.2;
  border: 1px solid #07102f;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  height: 46px;
  width: 181px;
  margin-right: 1rem;
  gap: 1rem;
  box-shadow: 0px 4px 10px rgba(70, 64, 200, 0.24);
  border-radius: 6px;
  background: #046af3;
  color: #ffffff;
  cursor: pointer;
  border: none;

  :hover {
    transition: 0.2s ease-in-out;
    background-color: #023985;
  }

  :disabled {
    background-color: #b0b0b0;
    cursor: default;
    box-shadow: none;
  }

  @media screen and (max-width: 600px) {
    margin-right: 0;
  }
`;
interface IContainerLeft {
  extends?: number;
}

export const ContainerLeft = styled.div<IContainerLeft>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${(props) =>
    props.extends ? `${props.extends * 1.5}rem` : "1.5rem"};

  @media screen and (max-width: 820px) {
    flex-direction: row-reverse;
    gap: 1rem;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
    margin-left: 0;
    width: 100%;
  }
`;
