import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import WaveSurfer from "wavesurfer.js";

interface IResponseError {
  count: number;
  error: boolean;
  description: string;
}

interface Contact {
  id: number;
  name: string;
  phone: string;
  email: string | null;
  workspace: number;
  createdAt: string;
}
interface FlowInteractions {
  customButtonModalOpen: boolean;
  sendFileModalOpen: boolean;
  entityModalOpen: boolean;
  sendFileSelectorSOOpen: boolean;
  addMoreTransferOptions: boolean;
  variablesModalOpen: boolean;
  modalOpenRobotActions: boolean;
  modalVariablesBotInteractions: boolean;
  nodeIsUsedAsVariableModal: boolean;
  customEntityModalOpen: boolean;
  customEntityUpdating: boolean;
  botInteractionIDActive: string;
  overlayInteractionsShow: boolean;
  entityIDUpdate: number;
  botIdActive: number;
  advisor: boolean;
  tokenActive: string;
  flowIdActive: number;
  openEntityDialog: boolean;
  insertEntity: boolean;
  responseError: IResponseError;
  fullScreenFlow: boolean;
  dialogInsertGCredentials: boolean;
  userInteractionId: string;
  updatedEntities: boolean;
  transferModal: boolean;
  tagModal: boolean;
  audioModal: boolean;
  emojiModal: boolean;
  closeRoomsModalOpen: boolean;
  transferRoomsModalOpen: boolean;
  modalFbLoginOpen: boolean;
  modalInstagramLoginOpen: boolean;
  modalClonarBotOpen: boolean;
  dialogActive: number;
  modalCreateTemplate: boolean;
  modalConfirmImportTemplate: boolean;
  newFilesIds: number[];
  valueTextEmoji: string;
  idxEmoji: number;
  newValueDialogId: number;
  recordedAudio: string;
  attachedAudio: string;
  newUrl: string[];
  customEntities: string[][];
  updateDataTable: boolean;
  showModalCSV: boolean;
  audioPlaying: any;
  modalChatbot: boolean;
  modalWebhook: boolean;
  modalTutorial: boolean;
  dataSelectedContacts: Contact[];
  buttonCreateCampaign: boolean;
}

const initialState: FlowInteractions = {
  customButtonModalOpen: false,
  modalOpenRobotActions: false,
  sendFileModalOpen: false,
  sendFileSelectorSOOpen: false,
  entityModalOpen: false,
  addMoreTransferOptions: false,
  advisor: false,
  variablesModalOpen: false,
  nodeIsUsedAsVariableModal: false,
  overlayInteractionsShow: false,
  botInteractionIDActive: "",
  customEntityModalOpen: false,
  customEntityUpdating: false,
  modalVariablesBotInteractions: false,
  userInteractionId: "",
  entityIDUpdate: -1,
  botIdActive: -1,
  tokenActive: "",
  flowIdActive: -1,
  openEntityDialog: false,
  insertEntity: false,
  responseError: {
    count: 0,
    error: false,
    description: ""
  },
  fullScreenFlow: false,
  dialogInsertGCredentials: false,
  updatedEntities: false,
  transferModal: false,
  tagModal: false,
  audioModal: false,
  emojiModal: false,
  closeRoomsModalOpen: false,
  transferRoomsModalOpen: false,
  modalFbLoginOpen: false,
  modalInstagramLoginOpen: false,
  modalClonarBotOpen: false,
  dialogActive: 0,
  modalCreateTemplate: false,
  modalConfirmImportTemplate: false,
  newFilesIds: [],
  valueTextEmoji: "",
  idxEmoji: 0,
  newValueDialogId: 0,
  recordedAudio: "",
  attachedAudio: "",
  newUrl: [],
  customEntities: [],
  updateDataTable: false,
  showModalCSV: false,
  audioPlaying: null,
  modalChatbot: false,
  modalWebhook: false,
  modalTutorial: false,
  dataSelectedContacts: [],
  buttonCreateCampaign: false
};
const flowInteractionsSlice = createSlice({
  name: "flowInteractions",
  initialState,
  reducers: {
    setModalConfirmImportTemplate(state, action: PayloadAction<boolean>) {
      state.modalConfirmImportTemplate = action.payload;
    },
    setModalCreateTemplate(state, action: PayloadAction<boolean>) {
      state.modalCreateTemplate = action.payload;
    },
    setModalFbLoginOpen(state, action: PayloadAction<boolean>) {
      state.modalFbLoginOpen = action.payload;
    },
    setModalClonarBotOpen(state, action: PayloadAction<boolean>) {
      state.modalClonarBotOpen = action.payload;
    },
    setModalInstagramLoginOpen(state, action: PayloadAction<boolean>) {
      state.modalInstagramLoginOpen = action.payload;
    },
    setTransferRoomsModalOpen(state, action: PayloadAction<boolean>) {
      state.transferRoomsModalOpen = action.payload;
    },
    setCloseRoomsModalOpen(state, action: PayloadAction<boolean>) {
      state.closeRoomsModalOpen = action.payload;
    },
    setSendFileModalOpen(state, action: PayloadAction<boolean>) {
      state.sendFileModalOpen = action.payload;
    },
    setAudioModalOpen(state, action: PayloadAction<boolean>) {
      state.audioModal = action.payload;
    },
    setEmojiModalOpen(state, action: PayloadAction<boolean>) {
      state.emojiModal = action.payload;
    },
    setSendsendFileSelectorSOOpenOpen(state, action: PayloadAction<boolean>) {
      state.sendFileSelectorSOOpen = action.payload;
    },
    setCustomButtonModalOpen(state, action: PayloadAction<boolean>) {
      state.customButtonModalOpen = action.payload;
    },
    setAddMoreTransferOptions(state, action: PayloadAction<boolean>) {
      state.addMoreTransferOptions = action.payload;
    },
    setOverlayInteractionsShow(state, action: PayloadAction<boolean>) {
      state.overlayInteractionsShow = action.payload;
    },
    // toggleAdvisor (state) {
    //   state.advisor = !state.advisor;
    // },
    setAdvisor(state, action: PayloadAction<boolean>) {
      state.advisor = action.payload;
    },
    toggleModalVariablesBotInteractions(state) {
      state.modalVariablesBotInteractions =
        !state.modalVariablesBotInteractions;
    },
    setModalOpenRobotActions(state, action: PayloadAction<boolean>) {
      state.modalOpenRobotActions = action.payload;
    },
    toggleTagModal(state) {
      state.tagModal = !state.tagModal;
    },
    toggleTransferModal(state) {
      state.transferModal = !state.transferModal;
    },
    setTransferModal(state, action: PayloadAction<boolean>) {
      state.transferModal = action.payload;
    },
    setUpdatedEntities(state) {
      state.updatedEntities = !state.updatedEntities;
    },
    setInsertEntity(state, action: PayloadAction<boolean>) {
      state.insertEntity = action.payload;
    },
    setFullScreenFlow(state, action: PayloadAction<boolean>) {
      state.fullScreenFlow = action.payload;
    },
    setEntitiesModalOpen(state, action: PayloadAction<boolean>) {
      state.entityModalOpen = action.payload;
    },
    setCustomEntitiesModalOpen(state, action: PayloadAction<boolean>) {
      state.customEntityModalOpen = action.payload;
    },
    setUserInteractionId(state, action: PayloadAction<string>) {
      state.userInteractionId = action.payload;
    },
    setVariablesModalOpen(state, action: PayloadAction<boolean>) {
      state.variablesModalOpen = action.payload;
    },
    setVariablesIsUsedOnAnotherNodeModalOpen(
      state,
      action: PayloadAction<boolean>
    ) {
      state.nodeIsUsedAsVariableModal = action.payload;
    },
    setbotInteractionIDActive(state, action: PayloadAction<string>) {
      state.botInteractionIDActive = action.payload;
    },
    setCustomEntityUpdating(state, action: PayloadAction<boolean>) {
      state.customEntityUpdating = action.payload;
    },
    setEntityIdUpdate(state, action: PayloadAction<number>) {
      state.entityIDUpdate = action.payload;
    },
    setBotIdActive(state, action: PayloadAction<number>) {
      state.botIdActive = action.payload;
    },
    setDialogActive(state, action: PayloadAction<number>) {
      state.dialogActive = action.payload;
    },
    setTokenActive(state, action: PayloadAction<string>) {
      state.tokenActive = action.payload;
    },
    setFlowIdActive(state, action: PayloadAction<number>) {
      state.flowIdActive = action.payload;
    },
    toggleOpenEntityDialog(state) {
      state.openEntityDialog = !state.openEntityDialog;
    },
    toggleGCredentialsDialog(state) {
      state.dialogInsertGCredentials = !state.dialogInsertGCredentials;
    },
    setResponseError(state, action: PayloadAction<IResponseError>) {
      state.responseError.count = action.payload.count;
      state.responseError.error = action.payload.error;
      state.responseError.description = action.payload.description;
    },
    addFilesIds(state, action: PayloadAction<number[]>) {
      state.newFilesIds = [...state.newFilesIds, ...action.payload];
    },
    deleteFileIds(state, action: PayloadAction<number>) {
      state.newFilesIds = state.newFilesIds.filter(
        (el) => el !== action.payload
      );
    },
    erasiFilesIds(state) {
      state.newFilesIds = [];
    },
    textEmoji(state, action: PayloadAction<string>) {
      state.valueTextEmoji = action.payload;
    },
    setIdxEmoji(state, action: PayloadAction<number>) {
      state.idxEmoji = action.payload;
    },
    setNewValueDialogId(state, action: PayloadAction<number>) {
      state.newValueDialogId = action.payload;
    },
    setRecordedAudioBlob(state, action: PayloadAction<string>) {
      state.recordedAudio = action.payload;
    },
    clearRecordedAudio(state) {
      state.recordedAudio = "";
    },
    setAttachedAudio(state, action: PayloadAction<string>) {
      state.attachedAudio = action.payload;
    },
    addUrls(state, action: PayloadAction<string[]>) {
      state.newUrl = [...state.newUrl, ...action.payload];
    },
    removeUrls(state) {
      state.newUrl = [];
    },
    setCustomEntities(state, action: PayloadAction<string[][]>) {
      state.customEntities = action.payload;
    },
    clearEntities(state) {
      state.customEntities = [];
    },
    setUpdateDataTable(state, action: PayloadAction<boolean>) {
      state.updateDataTable = action.payload;
    },
    setShowModalCSV(state, action: PayloadAction<boolean>) {
      state.showModalCSV = action.payload;
    },
    setAudioPlaying: (state, action: PayloadAction<WaveSurfer | null>) => {
      state.audioPlaying = action.payload;
    },
    setModalChatbot: (state, action: PayloadAction<boolean>) => {
      state.modalChatbot = action.payload;
    },
    setModalWebhook: (state, action: PayloadAction<boolean>) => {
      state.modalWebhook = action.payload;
    },
    setModalTutorial: (state, action: PayloadAction<boolean>) => {
      state.modalTutorial = action.payload;
    },
    setDataSelectedContacts(state, action: PayloadAction<Contact[]>) {
      state.dataSelectedContacts = action.payload;
    },
    setButtonCreateCampaign: (state, action: PayloadAction<boolean>) => {
      state.buttonCreateCampaign = action.payload;
    }
  }
});

export const {
  setCustomButtonModalOpen,
  setSendsendFileSelectorSOOpenOpen,
  toggleTransferModal,
  setTransferModal,
  setAdvisor,
  setEntitiesModalOpen,
  setFullScreenFlow,
  setVariablesModalOpen,
  setbotInteractionIDActive,
  setCustomEntitiesModalOpen,
  setCustomEntityUpdating,
  setEntityIdUpdate,
  setVariablesIsUsedOnAnotherNodeModalOpen,
  toggleModalVariablesBotInteractions,
  setOverlayInteractionsShow,
  setBotIdActive,
  setTokenActive,
  setUserInteractionId,
  setFlowIdActive,
  toggleOpenEntityDialog,
  setInsertEntity,
  setResponseError,
  toggleGCredentialsDialog,
  setUpdatedEntities,
  toggleTagModal,
  setModalOpenRobotActions,
  setAddMoreTransferOptions,
  setSendFileModalOpen,
  setAudioModalOpen,
  setEmojiModalOpen,
  setCloseRoomsModalOpen,
  setTransferRoomsModalOpen,
  setModalFbLoginOpen,
  setModalInstagramLoginOpen,
  setModalClonarBotOpen,
  setDialogActive,
  setModalCreateTemplate,
  setModalConfirmImportTemplate,
  addFilesIds,
  deleteFileIds,
  erasiFilesIds,
  textEmoji,
  setIdxEmoji,
  setNewValueDialogId,
  setRecordedAudioBlob,
  clearRecordedAudio,
  setAttachedAudio,
  addUrls,
  removeUrls,
  setCustomEntities,
  clearEntities,
  setUpdateDataTable,
  setShowModalCSV,
  setAudioPlaying,
  setModalChatbot,
  setModalWebhook,
  setModalTutorial,
  setDataSelectedContacts,
  setButtonCreateCampaign
} = flowInteractionsSlice.actions;

export default flowInteractionsSlice.reducer;
