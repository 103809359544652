/* eslint-disable no-nested-ternary */
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setRChatBots } from "../../redux/slices/chatbots";
import { useEffect, useState } from "react";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import BotsServices from "../../services/bots";
import { IResponseGetChatbots } from "../../types/chatbots";
import { Chatbot } from "./components/chatbot";
import { Text } from "../Text";
import { ProgressSpinner } from "primereact/progressspinner";
import { useToast } from "../toast-provider";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { TopInfo } from "./components/top-info";

export const Chatbots: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { GetBots } = BotsServices();
  const { user, company } = useAuth() as AuthContextType;
  const dispatch = useAppDispatch();
  const toast = useToast();

  const workspacesSelected = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );

  const chatbots = useAppSelector((state) => state.chatbots.chatbots);
  const botResume = useAppSelector((state) => state.bot);

  const activeFlowId = useAppSelector(
    (state) => state.flowInteractions.flowIdActive
  );

  const [search, setSearch] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTypingTimeout = setTimeout(() => {
      setSearch(inputValue);
    }, 1000);

    setTypingTimeout(newTypingTimeout);
  };

  const cleanSeach = () => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setSearch("");
    setInputValue("");
  };

  const GetChatbots = async () => {
    setLoading(true);

    try {
      const results: IResponseGetChatbots = await GetBots({
        limit: rows,
        offset: first,
        companyId: user.admin ? company?.id : user.companyId,
        workspaces: workspacesSelected,
        name: search
      });
      dispatch(setRChatBots(results.result));
      setLoading(false);

      setTotalCount(results.total);
    } catch (error) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail: "Erro ao buscar chatbots. Por favor tente mais tarde."
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    if (workspacesSelected && workspacesSelected.length > 0) {
      GetChatbots();
    }
  }, [company?.id, workspacesSelected, search, location.pathname, first, rows]);

  const inputStyles = css`
    width: 342px;

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  `;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem;
        gap: 10px;
        overflow: hidden;
        background: #f5f5f5;
      `}
    >
      <TopInfo totalCount={totalCount} />

      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: 1rem;
          gap: 20px;
          border-radius: 10px;
          overflow: hidden;
          background: #ffffff;
        `}
      >
        <span
          className={
            inputValue
              ? "p-input-icon-left p-input-icon-right"
              : "p-input-icon-left"
          }
          css={inputStyles}
        >
          <i className="pi pi-search" />

          <InputText
            value={inputValue}
            onChange={(e) => {
              handleSearch(e);

              setInputValue(e.target.value);
            }}
            className="p-inputtext-lg"
            placeholder="Buscar"
            css={css`
              width: 100%;
            `}
          />

          {inputValue && (
            <i
              onClick={() => cleanSeach()}
              className="pi pi-times"
              css={css`
                cursor: pointer;
              `}
            />
          )}
        </span>

        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 15px;IChatbot
            overflow: hidden;
          `}
        >
          {loading ? (
            <ProgressSpinner />
          ) : chatbots?.length ? (
            chatbots?.map((chatbot) => (
              <Chatbot
                active={chatbot.active}
                createdAt={chatbot.createdAt}
                dialogs={chatbot.dialogs}
                isWhatsappQrCodeConnected={chatbot.isWhatsappQrCodeConnected}
                id={chatbot.id}
                name={chatbot.name}
                updatedAt={chatbot.updatedAt}
                updatedBy={chatbot.updatedBy}
                key={chatbot.id}
                activeFlowId={activeFlowId || chatbot.activeFlowId}
                token={chatbot.token}
                target={chatbot.target}
              />
            ))
          ) : (
            <div
              css={css`
                padding: 1rem 0;
              `}
            >
              <Text s="1.5rem" weight={600}>
                Não existem chatbots cadastrados para os workspaces
                selecionados.
              </Text>
            </div>
          )}
        </div>
      </div>

      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalCount}
        rowsPerPageOptions={[5, 20, 50, 100]}
        onPageChange={onPageChange}
      />
    </div>
  );
};
