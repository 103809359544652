import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ImgIcon = styled.button`
  border: none;
  background-color: transparent;
  :hover {
    cursor: pointer;
  }
`;

export const ButtonPlus = css`
  position: absolute;
  width: 25px;
  height: 25px;
  right: 1.5rem;
  top: 40%;
  background-color: #d8e9f6;
  border-radius: 6px;
  border: none;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
  }
`;

export const Baloon = css`
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0px 16px;
  padding: 1rem;
  padding-right: 3rem;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  position: relative;
`;

export const ImageSixPoints = css`
  margin-right: 0.5rem;
`;

export const Flex = css`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
  justify-content: space-between;
`;

interface IMyImage {
  disabled?: boolean;
}
export const MyImage = styled.img<IMyImage>`
  filter: ${(props) =>
    props.disabled
      ? "invert(92%) sepia(94%) saturate(1778%) hue-rotate(178deg) brightness(104%) contrast(111%)"
      : ""};
  // color: ${(props) => (props.disabled ? "#CCE3FF" : "#E6F1FF")};
  margin-right: 0.5rem;
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
  }
`;

export const CheckButton = css`
  margin: 0 auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #0075ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 64px;
  border-radius: 0.5rem;
  &:disabled {
    color: #e6f1ff;
  }
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
    background-color: #cce3ff;
  }
`;

export const Divisor = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid #e5e5e5;
  margin: 0.5rem 0;
`;

export const AttributesStyle = styled.div`
  background: #ffffff;
  border: 1px solid #d8e9f6;
  border-radius: 6px;
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #544f77;
  /* z-index: 10; */
  /* overflow: hidden; */
  margin-top: 1rem;
  & + & {
    margin-top: 1rem;
  }
  /* :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  } */
`;

export const AttributeBar = styled.div`
  width: 0.25rem;
  height: 100%;
  background: #00cdac;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
`;

export const IconImage = styled.div`
  margin-left: 1rem;
  width: 2.6875rem;
  height: 2.6875rem;
  border-radius: 0.75rem;
  background: #d4e2f3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FileName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80px;

  @media (min-width: 1020px) {
    width: 115px;
  }
  @media (min-width: 1200px) {
    width: 170px;
  }
  @media (min-width: 1400px) {
    width: 225px;
  }
  @media (min-width: 1600px) {
    width: 275px;
  }
  @media (min-width: 1850px) {
    width: 325px;
  }
`;

export const DeleteButtonIcon = styled.button`
  width: 1.5625rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  color: #fff;
  position: absolute;
  top: -0.78125rem;
  right: -0.78125rem;
  z-index: 3;
`;
