import { useFetch2 } from "../../util";

interface IContactsList {
  offset: number;
  limit: number;
  workspaces: number[];
  description?: string;
  name?: string;
  phone?: string;
  email?: string;
  createdAtPeriod?: {
    startAt: string;
    endAt: string;
  };
}

export default () => {
  const fetch = useFetch2();

  const ListContacts = async (data: IContactsList) => {
    return fetch(`/api/contacts/list`, { method: "POST" }, data);
  };

  const SearchContacts = async (id: number) => {
    return fetch(`/api/contacts/${id}`, { method: "GET" });
  };

  const UpdateContacts = async (id: number, data: any) => {
    const method = { method: "PUT" };
    return fetch(`/api/contacts/${id}`, method, data);
  };

  return {
    ListContacts,
    SearchContacts,
    UpdateContacts
  };
};
