import { Sidebar } from "primereact/sidebar";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import * as comunCss from "../../../comum_css/index";
import { Dropdown } from "primereact/dropdown";
import {
  Attachment,
  botRed,
  ButtonDelete,
  Check,
  PhotoVideo,
  SixPoints,
  Smile
} from "../../../../assets";
import {
  AttributeBar,
  AttributesStyle,
  Baloon,
  ButtonPlus,
  CheckButton,
  DeleteButtonIcon,
  Divisor,
  FileName,
  Flex,
  IconImage,
  ImageSixPoints,
  ImgIcon,
  MyImage
} from "./styles";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { setEmojiModalOpen } from "../../../../redux/slices/flowInteractions";
import { EmojiModal } from "../../../../components/Interactions/components/interacaoRobo/EmojiModal";
import { IResponseGetChatbots } from "../../../../types/chatbots";
import BotsServices from "../../../../services/bots";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { useToast } from "../../../toast-provider";
import { Calendar } from "primereact/calendar";
import { MyCalendar } from "../../../../components/MyCalendar";
import CreateNewCampaign from "./use-campaign";

interface ICreateCampaign {
  visible: boolean;
  hide: () => void;
}

const CreateCampaign = ({ visible, hide }: ICreateCampaign) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [interval, setInterval] = useState(0);
  const [message, setMessage] = useState("");
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [bots, setBots] = useState<IResponseGetChatbots | null>(null);
  const { GetBots } = BotsServices();
  const { user, company } = useAuth() as AuthContextType;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [search, setSearch] = useState("");
  const toast = useToast();
  const [botSelected, setBotSelected] = useState<any | null>(null);
  const [startAt, setStartAt] = useState<string | null>(null);
  const [dateTime, setDateTime] = useState<Date | null>(null);
  const { newCampaign } = CreateNewCampaign();
  const [variable, setVariable] = useState("");
  const [variablesByContact, setVariablesByContact] = useState<{
    [contactId: number]: { [variableId: string]: string };
  }>({});

  const [variables, setVariables] = useState<
    { id: number; value: string; options: { name: string; value: string }[] }[]
  >([]);
  const workspacesSelected = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );
  const valueTextEmoji = useAppSelector(
    (state) => state.flowInteractions.valueTextEmoji
  );
  const dataSelectedContacts = useAppSelector(
    (state) => state.flowInteractions.dataSelectedContacts
  );
  const [filesData, setFilesData] = useState<
    { base64File: string; name: string }[]
  >([]);

  const intervalOptions = [
    { name: "1 minuto", value: 60 },
    { name: "2 minutos", value: 120 },
    { name: "3 minutos", value: 180 },
    { name: "4 minutos", value: 240 },
    { name: "5 minutos", value: 300 },
    { name: "10 minutos", value: 600 }
  ];

  const startAtOptions = [
    { name: "Imediato", value: "imediato" },
    { name: "Agendar", value: "agendar" }
  ];

  const handleStartOptionChange = (e: any) => {
    if (e.value === "imediato") {
      setStartAt("imediato");
      setDateTime(null);
    } else {
      setStartAt("agendar");
    }
  };

  useEffect(() => {
    if (valueTextEmoji) {
      setMessage((prevText) => {
        const start = prevText.slice(0, cursorPosition);
        const end = prevText.slice(cursorPosition);
        return start + valueTextEmoji + end;
      });
      setCursorPosition((prevPos) => prevPos + valueTextEmoji.length);
    }
  }, [valueTextEmoji]);

  const formatDate = (date: Date | null): string | null => {
    if (!date) return null;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    const timezoneOffset = -date.getTimezoneOffset();
    const offsetHours = String(
      Math.abs(Math.floor(timezoneOffset / 60))
    ).padStart(2, "0");
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(
      2,
      "0"
    );
    const offsetSign = timezoneOffset >= 0 ? "+" : "-";

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
  };

  const finalStartAt =
    startAt === "imediato"
      ? formatDate(new Date(new Date().getTime() + 5 * 60 * 1000))
      : dateTime
      ? formatDate(dateTime)
      : null;

  const dataNewCampaign = {
    name: name,
    message: message,
    interval: interval,
    botId: botSelected?.id || null,
    media: "WHATSAPP_QRCODE",
    file: filesData.length > 0 ? filesData[0] : null,
    startAt: finalStartAt,
    // workspaceId: 18,
    // header: null,
    // footer: null,
    // template: null,
    messages: dataSelectedContacts.map((contact) => {
      let finalMessage = message;

      variables.forEach((variable) => {
        const regex = new RegExp(`\\{\\{${String(variable.id)}\\}\\}`, "g");
        const contactValue =
          variablesByContact[contact.id]?.[variable.id] || "";

        const updatedMessage = finalMessage.replace(regex, contactValue);

        finalMessage = updatedMessage;
      });

      return {
        message: finalMessage,
        // header: null,
        // footer: null,
        contact: {
          createdAt: contact.createdAt,
          email: contact.email || null,
          id: contact.id,
          name: contact.name,
          phone: contact.phone,
          workspace: contact.workspace
          // workspaceId: contact.workspace,
        }
      };
    })
  };

  useEffect(() => {
    setVariables((prevVariables) =>
      prevVariables.filter((variable) => {
        const regex = new RegExp(`\\{\\{${variable.id}\\}\\}`, "g");
        return regex.test(message);
      })
    );
  }, [message]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileReaders = Array.from(files).map((file) => {
        return new Promise<{ base64File: string; name: string }>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64 = reader.result as string;
            resolve({
              base64File: base64,
              name: file.name
            });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(fileReaders).then((filesData) => {
        setFilesData((prevFilesData) => [...prevFilesData, ...filesData]);
      });
    }
  };

  const handleDeleteFile = (fileIndex: number) => {
    setFilesData((prevFilesData) =>
      prevFilesData.filter((_, index) => index !== fileIndex)
    );
  };

  const GetChatbots = async () => {
    if (workspacesSelected.length < 1) {
      return;
    }
    try {
      const results: IResponseGetChatbots = await GetBots({
        limit: rows,
        offset: first,
        companyId: user.admin ? company?.id : user.companyId,
        workspaces: workspacesSelected,
        name: search
      });
      setBots(results);
    } catch (error) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail: "Erro ao buscar chatbots. Por favor tente mais tarde."
      });
    }
  };

  useEffect(() => {
    if (location.pathname === "/contacts") {
      GetChatbots();
    }
  }, [location.pathname, workspacesSelected]);

  const handleAddVariable = () => {
    const newVariableId = variables.length + 1;

    const dynamicOptions = [
      { name: "Nome", value: "nome" },
      { name: "Telefone", value: "telefone" },
      { name: "E-mail", value: "email" }
    ];

    const variableText = `{{${newVariableId}}}`;

    setMessage((prevText) => {
      const start = prevText.slice(0, cursorPosition);
      const end = prevText.slice(cursorPosition);
      return start + variableText + end;
    });

    setVariables((prevVariables) => [
      ...prevVariables,
      {
        id: newVariableId,
        value: "",
        options: dynamicOptions
      }
    ]);

    setCursorPosition((prevPosition) => prevPosition + variableText.length);
  };

  const handleVariableChange = (variableId: number, selectedValue: string) => {
    setVariables((prevVariables) =>
      prevVariables.map((variable) =>
        variable.id === variableId
          ? { ...variable, value: selectedValue }
          : variable
      )
    );

    const newVariablesByContact = { ...variablesByContact };
    dataSelectedContacts.forEach((contact) => {
      if (!newVariablesByContact[contact.id]) {
        newVariablesByContact[contact.id] = {};
      }
      newVariablesByContact[contact.id][variableId] =
        selectedValue === "nome"
          ? contact.name
          : selectedValue === "telefone"
          ? contact.phone
          : contact.email || "";
    });
    setVariablesByContact(newVariablesByContact);
  };

  const handleCreateCampaign = async () => {
    if (!name || !interval || !botSelected || !finalStartAt) {
      toast?.current?.show({
        life: 3000,
        severity: "warn",
        summary: "Atenção",
        detail: "Preencha todos os campos obrigatórios antes de salvar."
      });
      return;
    }

    const currentTime = new Date();
    if (dateTime && dateTime < currentTime) {
      toast?.current?.show({
        life: 5000,
        severity: "warn",
        summary: "Data inválida",
        detail: "Não é permitido começar uma campanha com data retroativa."
      });
      return;
    }

    try {
      setLoading(true);

      const workspaceId =
        workspacesSelected.length > 0 ? workspacesSelected[0] : null;

      const campaignData = {
        ...dataNewCampaign,
        workspaceId
      };

      await newCampaign(campaignData);

      toast?.current?.show({
        life: 3000,
        severity: "success",
        summary: "Sucesso.",
        detail: "Campanha criada com sucesso."
      });

      setName("");
      setInterval(0);
      setBotSelected(null);
      setMessage("");
      setDateTime(null);
      setStartAt("imediato");
      setFilesData([]);
      setVariables([]);
      setVariablesByContact({});
      hide();
    } catch (error) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail: "Não foi possível criar a campanha. Tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Sidebar
      icons="Criar campanha"
      visible={visible}
      onHide={hide}
      position="right"
      className="wsDialog p-sidebar-sm"
      style={{ minWidth: "25rem" }}
    >
      <div style={{ minHeight: "250px" }}>
        <Dropdown
          style={{
            width: "100%",
            marginTop: "1rem",
            lineHeight: "1.3125rem"
          }}
          value={botSelected}
          options={bots?.result?.filter(
            (bot) => bot.target === 5 && bot.isWhatsappQrCodeConnected
          )}
          onChange={(e) => setBotSelected(e.value)}
          optionLabel="name"
          placeholder="Selecione o robô"
          filter={true}
          emptyMessage="Sem robôs disponíveis"
        />
        <div>
          <label css={comunCss.inputLabelDefault} htmlFor="name">
            Nome
          </label>
          <InputText
            className="w-full"
            id="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Insira o nome"
          />
        </div>
        <Dropdown
          style={{
            width: "100%",
            marginTop: "1rem",
            lineHeight: "1.3125rem"
          }}
          value={interval}
          options={intervalOptions}
          onChange={(e) => setInterval(e.value)}
          optionLabel="name"
          placeholder="Intervalo entre mensagens"
          filter={true}
        />
        <Dropdown
          style={{
            width: "100%",
            marginTop: "1rem",
            lineHeight: "1.3125rem"
          }}
          value={startAt}
          options={startAtOptions}
          onChange={handleStartOptionChange}
          optionLabel="name"
          placeholder="Iniciar em:"
          filter={true}
        />
        {startAt === "agendar" && (
          <div style={{ marginTop: "1rem" }}>
            <MyCalendar
              value={dateTime}
              onChange={(e) => setDateTime(e.value)}
              showTime
              hourFormat="24"
              dateFormat="yy-mm-dd"
              showIcon
              style={{ border: "1px solid #ced4da", borderRadius: "4px" }}
              placeholder="Escolha uma data"
              panelClassName="custom-calendar-panel"
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          style={{ border: "3px solid rgba(33, 150, 243, 0.3)" }}
          className="relative mt-4 p-2 w-full border-round-lg"
        >
          <div className="absolute -mt-4 ml-6 bg-white border-round-right-3xl flex">
            <div className="flex align-items-center">
              <div className="w-2rem h-2rem -ml-4 shadow-7 text-center border-circle bg-white align-items-center justify-content-center">
                <img
                  className="my-1"
                  width="19px"
                  src={botRed}
                  alt="Bot-Image"
                />
              </div>

              <div
                className="ml-1"
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#046AF3"
                }}
              >
                Mensagem
              </div>
            </div>
          </div>
          <div className="content mt-4 ml-2"></div>
          <div css={Baloon}>
            <img css={ImageSixPoints} src={SixPoints} alt="icon-six-points" />
            <InputTextarea
              style={{
                border: "0px",
                outline: "none",
                width: "100%",
                resize: "vertical"
              }}
              autoResize={true}
              onKeyUp={(e) => setCursorPosition(e.currentTarget.selectionStart)}
              onClick={(e) => setCursorPosition(e.currentTarget.selectionStart)}
              onSelect={(e) =>
                setCursorPosition(e.currentTarget.selectionStart)
              }
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Insira a mensagem aqui"
            />
            <button onClick={handleAddVariable} type="button" css={ButtonPlus}>
              +
            </button>
          </div>
          <div className="flex justify-content-end">
            <div>
              <div css={Flex}>
                <ImgIcon
                  onClick={() => {
                    dispatch(setEmojiModalOpen(true));
                  }}
                  className="tooltip-Smile"
                  data-pr-position="bottom"
                >
                  <img src={Smile} alt="Icon-Smiles" />
                </ImgIcon>
                <Tooltip target=".tooltip-Smile">Enviar emoji</Tooltip>
                <ImgIcon
                  onClick={() => document.getElementById("fileInput")?.click()}
                  className="tooltip-Attachment"
                  data-pr-position="bottom"
                >
                  <img src={Attachment} alt="Icon-Attachment" />
                </ImgIcon>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                  multiple
                />
                <Tooltip target=".tooltip-Attachment">Anexar arquivo</Tooltip>
                {/* <img src={Link} alt="Icon-Link" /> */}
                {/* <img src={MicBlue} alt="Icon-MicBlue" /> */}
                {/* <img src={ThreePointsBlue} alt="Icon-ThreePointsBlue" /> */}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "285px",
            gap: "10px",
            padding: "10px 0"
          }}
        >
          {filesData.length > 0 &&
            filesData.map((file, index) => (
              <AttributesStyle key={index}>
                <AttributeBar />
                <IconImage>
                  <img src={PhotoVideo} alt="Img Photo Video" />
                </IconImage>
                <div className="flex w-full align-items-center ml-2 justify-content-between">
                  <div className="flex align-items-center justify-content-start">
                    <FileName>{file.name}</FileName>
                  </div>
                  <DeleteButtonIcon onClick={() => handleDeleteFile(index)}>
                    <img src={ButtonDelete} width="10px" alt="Delete-Node" />
                  </DeleteButtonIcon>
                </div>
              </AttributesStyle>
            ))}

          {variables.map((variable) => (
            <Dropdown
              key={variable.id}
              value={variable.value}
              options={variable.options.map((option) => ({
                label: option.name,
                value: option.value
              }))}
              onChange={(event) =>
                handleVariableChange(variable.id, event.target.value)
              }
              placeholder={`Selecione uma variável para {{${variable.id}}}`}
            />
          ))}
        </div>

        <div>
          <Divisor />
          <button
            type="button"
            onClick={handleCreateCampaign}
            css={CheckButton}
            style={{ width: "100%", margin: 0 }}
          >
            {loading ? (
              <ProgressSpinner
                style={{
                  width: "1.8rem",
                  height: "1.8rem",
                  margin: "0 0.5rem"
                }}
              />
            ) : (
              <MyImage src={Check} alt="Check-Button" />
            )}
            <div style={{ fontWeight: "bold" }}>Salvar</div>
          </button>
        </div>
      </div>
      <EmojiModal heightEmojiPicker="330px" />
    </Sidebar>
  );
};

export default CreateCampaign;
