import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import ProfileService from "../../../services/ProfileService";

type PermissionTreeProps = {
  control: Control<any>;
};

const mapFn = (p) => ({ title: p.displayName, key: p.id, isLeaf: true });

export default ({ control }: PermissionTreeProps) => {
  const [treeNodes, setTreeNodes] = useState<any[]>([]);

  const { findPermissions } = ProfileService();

  useEffect(() => {
    const run = async () => {
      const permissions = await findPermissions();

      let key = 0;

      setTreeNodes([
        {
          title: "Entidades",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("entity"))
            .map(mapFn)
        },
        {
          title: "Robôs",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("bot"))
            .map(mapFn)
        },
        {
          title: "Colaboradores",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("user"))
            .map(mapFn)
        },
        {
          title: "Workspaces",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("workspace"))
            .map(mapFn)
        },
        {
          title: "Perfis",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("profile"))
            .map(mapFn)
        },
        {
          title: "Gestão de salas",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("dialog"))
            .map(mapFn)
        },
        {
          title: "Credenciais",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("credential"))
            .map(mapFn)
        },
        {
          title: "Dashboard",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("dashboard"))
            .map(mapFn)
        },
        {
          title: "Omnichannel",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("omnichannel"))
            .map(mapFn)
        },
        {
          title: "Templates",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("template"))
            .map(mapFn)
        },
        {
          title: "Contatos",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("contact"))
            .map(mapFn)
        },
        {
          title: "Campanha",
          key: key--,
          children: permissions
            .filter((p) => p.name.startsWith("campaign"))
            .map(mapFn)
        }
      ]);
    };

    run();
  }, []);

  return (
    <Controller
      name="permissions"
      control={control}
      render={({ field }) => (
        <Tree
          checkable={true}
          onCheck={(e) => field.onChange(e)}
          checkedKeys={field.value}
          treeData={treeNodes}
          showIcon={false}
        />
      )}
    />
  );
};
