import { useFetch2 } from "../../../../util";

export default () => {
  const fetch = useFetch2();

  const newCampaign = async (data: any) => {
    return fetch(`/api/campaigns`, { method: "POST" }, data);
  };

  return { newCampaign };
};
