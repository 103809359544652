import { useFetch2 } from "../../util";

export default () => {
  const fetch = useFetch2();

  const listCampaigns = async (data: any) => {
    return fetch(`/api/campaigns/list`, { method: "POST" }, data);
  };

  const startCampaign = async (id: number) => {
    return fetch(`/api/campaigns/${id}/start`, { method: "POST" }, id);
  };

  const pauseCampaign = async (id: any) => {
    return fetch(`/api/campaigns/${id}/pause`, { method: "POST" }, id);
  };

  const cancelCampaign = async (id: any) => {
    return fetch(`/api/campaigns/${id}/cancel`, { method: "POST" }, id);
  };

  return { listCampaigns, startCampaign, pauseCampaign, cancelCampaign };
};
