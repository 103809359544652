import { css } from "@emotion/react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { botRed, NewWorkspace } from "../../../assets";
import { useAppDispatch } from "../../../redux/hooks";
import { setModeWorkspaces } from "../../../redux/slices/workspaces";

interface IWorkspaceSidebar {
  hideSidebar: () => void;
  showDialog: (dialog: string) => void;
  visible: boolean;
}
const WorkspaceSidebar: React.FC<IWorkspaceSidebar> = ({
  hideSidebar,
  showDialog,
  visible
}) => {
  const dispatch = useAppDispatch();
  const onHideSideBar = (dialog: string) => {
    hideSidebar();
    showDialog(dialog);
    dispatch(setModeWorkspaces("add"));
  };

  return (
    <Sidebar
      icons="Adicionar"
      visible={visible}
      onHide={hideSidebar}
      position="right"
      className="wsDialog p-sidebar-sm"
    >
      <div className="flex flex-column mt-1 btnConfiWorkspaceContainer">
        <Button
          onClick={() => onHideSideBar("workspace")}
          aria-label="Secondary"
          className="mt-2 p-button-outlined p-button-secondary btnConfiWorkspace blueColorBtnConfig"
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <div className="px-2 blueIconBTN">
              <img
                src={NewWorkspace}
                width={16}
                height={16}
                css={css`
                  align-items: center;
                  display: flex;
                  justify-content: center;
                `}
              />
            </div>
            <span
              className="px-3 text-sm"
              css={css`
                align-items: center;
                display: flex;
              `}
            >
              Novo workspace
            </span>
          </div>
          <span className="px-3" style={{ color: "#C4CCD8" }}>
            {" "}
            &gt;{" "}
          </span>
        </Button>

        <Button
          onClick={() => onHideSideBar("robo")}
          aria-label="Secondary"
          className="mt-2 p-button-outlined p-button-secondary btnConfiWorkspace redColorBtnConfig"
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <div className="px-2 redIconBTN">
              <img
                src={botRed}
                width={16}
                height={16}
                css={css`
                  align-items: center;
                  display: flex;
                  justify-content: center;
                `}
              />
            </div>
            <span
              className="px-3 text-sm"
              css={css`
                align-items: center;
                display: flex;
              `}
            >
              Novo Robô
            </span>
          </div>
          <span className="px-3" style={{ color: "#C4CCD8" }}>
            {" "}
            &gt;{" "}
          </span>
        </Button>

        <Button
          onClick={() => onHideSideBar("profile")}
          aria-label="Secondary"
          className="mt-2 p-button-outlined p-button-secondary btnConfiWorkspace blueColorBtnConfig"
        >
          <div>
            <i className="pi pi-plus px-2 blueIconBTN"></i>
            <span className="px-3 text-sm">Novo perfil</span>
          </div>
          <span className="px-3" style={{ color: "#C4CCD8" }}>
            {" "}
            &gt;{" "}
          </span>
        </Button>
      </div>
    </Sidebar>
  );
};

export default WorkspaceSidebar;
